<template>
  <BaseLayout>
    <div id="content">
      <div class="container">
        <header class="mb-5">
          <div class="row">
            <div class="col-md mb-4 mb-md-0">
              <h1 class="theme-text">Compare Products</h1>
            </div>
            <div class="col-md-auto" v-if="selectedProducts.length > 0">
              <button
                class="btn btn-secondary mr-2"
                @click="reset"
                id="compare-products-reset-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.814"
                  height="16.814"
                  viewBox="0 0 16.814 16.814"
                >
                  <path
                    id="Path_1746"
                    data-name="Path 1746"
                    d="M376.148,366.512a8.36,8.36,0,0,0-.476-1.53,8.008,8.008,0,0,0-.752-1.384,8.122,8.122,0,0,0-1-1.209,8.479,8.479,0,0,0-1.207-1,8.107,8.107,0,0,0-1.385-.749,7.926,7.926,0,0,0-1.533-.477,8.237,8.237,0,0,0-1.641-.166.34.34,0,0,0,0,.68,7.551,7.551,0,0,1,1.506.152,7.235,7.235,0,0,1,1.4.437,7.422,7.422,0,0,1,1.27.686,7.779,7.779,0,0,1,1.108.915,7.45,7.45,0,0,1,.912,1.108,7.334,7.334,0,0,1,.689,1.267,7.428,7.428,0,0,1,.589,2.912,7.6,7.6,0,0,1-.152,1.507,7.682,7.682,0,0,1-.437,1.4,7.332,7.332,0,0,1-.69,1.269,7.453,7.453,0,0,1-.911,1.106,7.782,7.782,0,0,1-1.108.915,7.447,7.447,0,0,1-1.273.688,7.247,7.247,0,0,1-1.4.436,7.579,7.579,0,0,1-3.014,0,7.258,7.258,0,0,1-1.4-.436,7.519,7.519,0,0,1-1.27-.687,7.627,7.627,0,0,1-1.106-.916,7.309,7.309,0,0,1-.913-1.106,7.69,7.69,0,0,1-.69-1.268,7.5,7.5,0,0,1-.435-1.405,7.515,7.515,0,0,1,.434-4.417,7.708,7.708,0,0,1,.691-1.27,7.313,7.313,0,0,1,.914-1.107q.2-.2.407-.379v2.241a.34.34,0,1,0,.68,0v-2.919a.355.355,0,0,0,0-.04v-.067a.34.34,0,0,0-.34-.34h-3.025a.34.34,0,0,0,0,.68h2.161q-.185.164-.363.342a8,8,0,0,0-1,1.209,8.4,8.4,0,0,0-.753,1.385,8.183,8.183,0,0,0-.473,1.53,8.238,8.238,0,0,0,0,3.289,8.19,8.19,0,0,0,.474,1.534,8.372,8.372,0,0,0,.752,1.383,7.984,7.984,0,0,0,1,1.208,8.291,8.291,0,0,0,1.206,1,8.18,8.18,0,0,0,1.385.749,7.94,7.94,0,0,0,1.533.477,8.226,8.226,0,0,0,3.285,0,7.925,7.925,0,0,0,1.532-.476,8.1,8.1,0,0,0,1.388-.751,8.466,8.466,0,0,0,1.207-1,8.108,8.108,0,0,0,.994-1.207,8.006,8.006,0,0,0,.753-1.386,8.2,8.2,0,0,0,.476-4.82Z"
                    transform="translate(-359.75 -359.75)"
                    fill="#696969"
                    stroke="#696969"
                    stroke-width="0.5"
                  />
                </svg>
                Reset
              </button>
              <b-button
                v-if="requiredProfileType"
                class="btn btn-primary bg-primary-light mr-2"
                @click="generateUrl"
              >
                Generate URL
              </b-button>
              <button
                class="btn btn-primary theme-bg"
                data-toggle="modal"
                data-target="#email-products-comparion"
              >
                Email Customer
              </button>
            </div>
          </div>
        </header>
        <div
          class="comparison-wrapper table-responsive"
          v-bind:class="[selectedProducts.length > 0 ? '' : 'overflow-hidden']"
        >
          <table class="table1" v-if="selectedProducts.length > 0">
            <tbody>
              <tr>
                <td>
                  <header>
                    <table class="inner-table">
                      <tbody>
                        <tr>
                          <th>
                            <p>
                              Here are the
                              <a href="#">{{ products[0].category.name }}</a>
                              you’ve chosen to compare
                            </p>
                            <p class="show">Show Differences</p>
                            <label class="toggle-switch" for="switch1">
                              <input
                                type="checkbox"
                                class="show-difference"
                                id="switch1"
                              />
                              <span class="slider">
                                <span class="yes">Yes</span>
                                <span class="no">No</span>
                              </span>
                            </label>
                          </th>
                          <td v-for="product in products" :key="product.id">
                            <div class="product">
                              <button
                                class="close"
                                @click="removeProduct(product)"
                              >
                                &times;
                              </button>
                              <div class="image">
                                <img
                                  :src="
                                    'https://media3.bosch-home.com/Product_Shots/600x337/' +
                                      product.featured_image +
                                      '.jpg'
                                  "
                                  :alt="product.name"
                                />
                              </div>
                              <div class="details">
                                <h4>
                                  {{ product.brand.name }} {{ product.series }}
                                  {{ product.name }}
                                </h4>
                                <p class="mb-0">{{ product.code }}</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </header>
                </td>
              </tr>
              <template v-for="section in sections">
                <template v-if="section.attributes.length > 0">
                  <tr :key="section.code">
                    <td>
                      <button
                        data-toggle="collapse"
                        :data-target="'#section-' + section.code"
                        aria-expanded="true"
                        :aria-controls="'section-' + section.code"
                        class="accordion-head"
                      >
                        {{ section.name }}
                      </button>
                    </td>
                  </tr>
                  <tr :key="section.code">
                    <td>
                      <div
                        class="collapse show table-wrapper"
                        :id="'section-' + section.code"
                      >
                        <table class="inner-table">
                          <tbody>
                            <tr
                              v-for="attr in section.attributes"
                              :key="attr.attribute.api_name"
                            >
                              <th>{{ attr.attribute.name }}</th>
                              <td v-for="product in products" :key="product.id">
                                {{
                                  getProductAttributeValue(
                                    product,
                                    attr.attribute
                                  )
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>

          <table class="table1" v-else>
            <tbody>
              <tr>
                <td>
                  <header>
                    <table class="inner-table">
                      <tbody>
                        <tr>
                          <th class="inner">
                            <p>
                              You have selected
                              <span class="theme-text"> 0 products </span> to
                              compare
                            </p>
                            <p>
                              <router-link to="/">
                                Start Searching<svg
                                  class="ml-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="4.421"
                                  height="6.948"
                                  viewBox="0 0 4.421 6.948"
                                >
                                  <g
                                    id="small-down"
                                    transform="translate(0 6.948) rotate(-90)"
                                  >
                                    <path
                                      id="Path_26"
                                      data-name="Path 26"
                                      d="M6.074,9.021,2.6,5.51l.9-.91L6.074,7.2,8.647,4.6l.9.91Z"
                                      transform="translate(-2.6 -4.6)"
                                      fill="#095691"
                                    />
                                  </g>
                                </svg>
                              </router-link>
                            </p>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </header>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <EmailModal :productIds="selectedProductIds" :currentUser="currentUser" />
    <b-modal
      id="generate-url-popup"
      centered
      title="Generated URL"
      busy="true"
      hide-footer
    >
      <div
        class="form-control d-flex align-items-center testing-code px-3 mb-2"
      >
        <span class="code">{{ generatedUrl }}</span>
        <input type="hidden" id="generated-url" :value="generatedUrl" />
      </div>
      <div
        v-if="urlCopiedSuccessfully"
        class="text-success mb-3 font-weight-bold"
      >
        URL copied to clipboard successfully!
      </div>
      <div class="text-right mb-2">
        <button
          class="btn btn-secondary-dark mr-2"
          @click="
            $bvModal.hide('generate-url-popup');
            urlCopiedSuccessfully = false;
          "
        >
          Cancel
        </button>
        <button class="btn btn-primary" @click.stop.prevent="copyGeneratedUrl">
          Copy
        </button>
      </div>
    </b-modal>
  </BaseLayout>
</template>
<script>
/* global $ */
import _ from "lodash";
import BaseLayout from "@/layouts/base";
import ProductService from "@/services/product.service";
import PermalinkService from "@/services/permalink.service";
import AuthService from "@/services/auth.service";
import EmailModal from "./EmailModal";

export default {
  name: "CompareProducts",
  components: {
    BaseLayout,
    EmailModal,
  },
  data: function() {
    return {
      products: [],
      sections: [
        { name: "Overview", code: "1", attributes: [] },
        { name: "Key Features", code: "2", attributes: [] },
      ],
      filters: {},
      generatedUrl: "",
      urlCopiedSuccessfully: false,
      requiredProfileType: false,
      currentUser: null,
    };
  },
  created() {
    this.getProducts();
  },
  watch: {},
  mounted() {
    AuthService.currentUser().then((user) => {
      this.currentUser = user;
      user.profile_type === "contact_center"
        ? (this.requiredProfileType = true)
        : (this.requiredProfileType = false);
    });
    $(document).on("click", ".show-difference", function() {
      if (this.checked) {
        $(".table-wrapper .inner-table tbody tr").each((tr_idx, tr) => {
          var value = "";
          $(tr)
            .children("td")
            .each((td_idx, td) => {
              var value1 = value;
              var value2 = $(td).text();
              value = value2;
              if (value1) {
                if (value1 != value2) {
                  $(tr).addClass("active");
                }
              }
            });
        });
      } else {
        $(".table-wrapper .inner-table tbody tr").removeClass("active");
      }
    });
  },
  computed: {
    selectedProducts() {
      return this.$store.state.product.selectedProducts;
    },
    selectedProductIds() {
      return _.map(this.selectedProducts, "id").toString();
    },
    selectedBrand() {
      return this.$store.state.brand.selectedBrand;
    },
    isBrandSelected() {
      return this.$store.state.brand.selectedBrand != null;
    },
  },
  methods: {
    getProducts: function() {
      if (this.selectedProducts.length > 0) {
        this.filters["id__in"] = _.map(this.selectedProducts, "id").toString();
        this.filters["include_discontinued"] = true;
      }

      ProductService.list(this.filters).then((response) => {
        this.products = response.results;
        _.each(this.sections, (section) => {
          section.attributes = _.filter(
            this.products[0].category.category_attributes,
            (a) => {
              return (
                a.attribute.section == section.code &&
                a.use_for_comparison == true
              );
            }
          );
        });
      });
    },
    removeProduct(product) {
      this.$store.dispatch("product/remove", product);
      this.getProducts();
    },
    reset() {
      this.$store.dispatch("product/reset");
      this.products = [];
    },
    getProductAttributeValue(product, attribute) {
      const requiredAttribute = _.find(product.product_attributes, (a) => {
        return a.attribute.id == attribute.id;
      });
      return requiredAttribute ? requiredAttribute.value : "N/A";
    },

    generateUrl() {
      this.urlCopiedSuccessfully = false;
      const viewType = "comparison";
      const productsIds = [];
      this.products.map((product) => {
        productsIds.push(product.id);
      });
      PermalinkService.create(viewType, productsIds).then(
        (response) => {
          this.generatedUrl = `https://bshappliancesdirect.co.uk/public/compare-products/${response.uuid}?utm_campaign=${this.currentUser.id}`;
          this.$bvModal.show("generate-url-popup");
        },
        (error) => {
          console.log(error);
        }
      );
    },
    copyGeneratedUrl() {
      let generatedUrlToCopy = document.querySelector("#generated-url");
      generatedUrlToCopy.setAttribute("type", "text");
      generatedUrlToCopy.select();
      var successful = document.execCommand("copy");
      successful
        ? (this.urlCopiedSuccessfully = true)
        : (this.urlCopiedSuccessfully = false);
      generatedUrlToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>
<style scoped></style>
