var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseLayout",
    [
      _c("div", { attrs: { id: "content" } }, [
        _c("div", { staticClass: "container" }, [
          _c("header", { staticClass: "mb-5" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md mb-4 mb-md-0" }, [
                _c("h1", { staticClass: "theme-text" }, [
                  _vm._v("Compare Products")
                ])
              ]),
              _vm.selectedProducts.length > 0
                ? _c(
                    "div",
                    { staticClass: "col-md-auto" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          attrs: { id: "compare-products-reset-button" },
                          on: { click: _vm.reset }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "16.814",
                                height: "16.814",
                                viewBox: "0 0 16.814 16.814"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  id: "Path_1746",
                                  "data-name": "Path 1746",
                                  d:
                                    "M376.148,366.512a8.36,8.36,0,0,0-.476-1.53,8.008,8.008,0,0,0-.752-1.384,8.122,8.122,0,0,0-1-1.209,8.479,8.479,0,0,0-1.207-1,8.107,8.107,0,0,0-1.385-.749,7.926,7.926,0,0,0-1.533-.477,8.237,8.237,0,0,0-1.641-.166.34.34,0,0,0,0,.68,7.551,7.551,0,0,1,1.506.152,7.235,7.235,0,0,1,1.4.437,7.422,7.422,0,0,1,1.27.686,7.779,7.779,0,0,1,1.108.915,7.45,7.45,0,0,1,.912,1.108,7.334,7.334,0,0,1,.689,1.267,7.428,7.428,0,0,1,.589,2.912,7.6,7.6,0,0,1-.152,1.507,7.682,7.682,0,0,1-.437,1.4,7.332,7.332,0,0,1-.69,1.269,7.453,7.453,0,0,1-.911,1.106,7.782,7.782,0,0,1-1.108.915,7.447,7.447,0,0,1-1.273.688,7.247,7.247,0,0,1-1.4.436,7.579,7.579,0,0,1-3.014,0,7.258,7.258,0,0,1-1.4-.436,7.519,7.519,0,0,1-1.27-.687,7.627,7.627,0,0,1-1.106-.916,7.309,7.309,0,0,1-.913-1.106,7.69,7.69,0,0,1-.69-1.268,7.5,7.5,0,0,1-.435-1.405,7.515,7.515,0,0,1,.434-4.417,7.708,7.708,0,0,1,.691-1.27,7.313,7.313,0,0,1,.914-1.107q.2-.2.407-.379v2.241a.34.34,0,1,0,.68,0v-2.919a.355.355,0,0,0,0-.04v-.067a.34.34,0,0,0-.34-.34h-3.025a.34.34,0,0,0,0,.68h2.161q-.185.164-.363.342a8,8,0,0,0-1,1.209,8.4,8.4,0,0,0-.753,1.385,8.183,8.183,0,0,0-.473,1.53,8.238,8.238,0,0,0,0,3.289,8.19,8.19,0,0,0,.474,1.534,8.372,8.372,0,0,0,.752,1.383,7.984,7.984,0,0,0,1,1.208,8.291,8.291,0,0,0,1.206,1,8.18,8.18,0,0,0,1.385.749,7.94,7.94,0,0,0,1.533.477,8.226,8.226,0,0,0,3.285,0,7.925,7.925,0,0,0,1.532-.476,8.1,8.1,0,0,0,1.388-.751,8.466,8.466,0,0,0,1.207-1,8.108,8.108,0,0,0,.994-1.207,8.006,8.006,0,0,0,.753-1.386,8.2,8.2,0,0,0,.476-4.82Z",
                                  transform: "translate(-359.75 -359.75)",
                                  fill: "#696969",
                                  stroke: "#696969",
                                  "stroke-width": "0.5"
                                }
                              })
                            ]
                          ),
                          _vm._v(" Reset ")
                        ]
                      ),
                      _vm.requiredProfileType
                        ? _c(
                            "b-button",
                            {
                              staticClass:
                                "btn btn-primary bg-primary-light mr-2",
                              on: { click: _vm.generateUrl }
                            },
                            [_vm._v(" Generate URL ")]
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary theme-bg",
                          attrs: {
                            "data-toggle": "modal",
                            "data-target": "#email-products-comparion"
                          }
                        },
                        [_vm._v(" Email Customer ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "comparison-wrapper table-responsive",
              class: [_vm.selectedProducts.length > 0 ? "" : "overflow-hidden"]
            },
            [
              _vm.selectedProducts.length > 0
                ? _c("table", { staticClass: "table1" }, [
                    _c(
                      "tbody",
                      [
                        _c("tr", [
                          _c("td", [
                            _c("header", [
                              _c("table", { staticClass: "inner-table" }, [
                                _c("tbody", [
                                  _c(
                                    "tr",
                                    [
                                      _c("th", [
                                        _c("p", [
                                          _vm._v(" Here are the "),
                                          _c("a", { attrs: { href: "#" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.products[0].category.name
                                              )
                                            )
                                          ]),
                                          _vm._v(" you’ve chosen to compare ")
                                        ]),
                                        _c("p", { staticClass: "show" }, [
                                          _vm._v("Show Differences")
                                        ]),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "toggle-switch",
                                            attrs: { for: "switch1" }
                                          },
                                          [
                                            _c("input", {
                                              staticClass: "show-difference",
                                              attrs: {
                                                type: "checkbox",
                                                id: "switch1"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "slider" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "yes" },
                                                  [_vm._v("Yes")]
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "no" },
                                                  [_vm._v("No")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._l(_vm.products, function(product) {
                                        return _c("td", { key: product.id }, [
                                          _c(
                                            "div",
                                            { staticClass: "product" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "close",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.removeProduct(
                                                        product
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" × ")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "image" },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        "https://media3.bosch-home.com/Product_Shots/600x337/" +
                                                        product.featured_image +
                                                        ".jpg",
                                                      alt: product.name
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "details" },
                                                [
                                                  _c("h4", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          product.brand.name
                                                        ) +
                                                        " " +
                                                        _vm._s(product.series) +
                                                        " " +
                                                        _vm._s(product.name) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c(
                                                    "p",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(product.code)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      })
                                    ],
                                    2
                                  )
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _vm._l(_vm.sections, function(section) {
                          return [
                            section.attributes.length > 0
                              ? [
                                  _c("tr", { key: section.code }, [
                                    _c("td", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "accordion-head",
                                          attrs: {
                                            "data-toggle": "collapse",
                                            "data-target":
                                              "#section-" + section.code,
                                            "aria-expanded": "true",
                                            "aria-controls":
                                              "section-" + section.code
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(section.name) + " "
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("tr", { key: section.code }, [
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "collapse show table-wrapper",
                                          attrs: {
                                            id: "section-" + section.code
                                          }
                                        },
                                        [
                                          _c(
                                            "table",
                                            { staticClass: "inner-table" },
                                            [
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  section.attributes,
                                                  function(attr) {
                                                    return _c(
                                                      "tr",
                                                      {
                                                        key:
                                                          attr.attribute
                                                            .api_name
                                                      },
                                                      [
                                                        _c("th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              attr.attribute
                                                                .name
                                                            )
                                                          )
                                                        ]),
                                                        _vm._l(
                                                          _vm.products,
                                                          function(product) {
                                                            return _c(
                                                              "td",
                                                              {
                                                                key: product.id
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getProductAttributeValue(
                                                                        product,
                                                                        attr.attribute
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ])
                : _c("table", { staticClass: "table1" }, [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [
                          _c("header", [
                            _c("table", { staticClass: "inner-table" }, [
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", { staticClass: "inner" }, [
                                    _c("p", [
                                      _vm._v(" You have selected "),
                                      _c(
                                        "span",
                                        { staticClass: "theme-text" },
                                        [_vm._v(" 0 products ")]
                                      ),
                                      _vm._v(" to compare ")
                                    ]),
                                    _c(
                                      "p",
                                      [
                                        _c(
                                          "router-link",
                                          { attrs: { to: "/" } },
                                          [
                                            _vm._v(" Start Searching"),
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  width: "4.421",
                                                  height: "6.948",
                                                  viewBox: "0 0 4.421 6.948"
                                                }
                                              },
                                              [
                                                _c(
                                                  "g",
                                                  {
                                                    attrs: {
                                                      id: "small-down",
                                                      transform:
                                                        "translate(0 6.948) rotate(-90)"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        id: "Path_26",
                                                        "data-name": "Path 26",
                                                        d:
                                                          "M6.074,9.021,2.6,5.51l.9-.91L6.074,7.2,8.647,4.6l.9.91Z",
                                                        transform:
                                                          "translate(-2.6 -4.6)",
                                                        fill: "#095691"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
            ]
          )
        ])
      ]),
      _c("EmailModal", {
        attrs: {
          productIds: _vm.selectedProductIds,
          currentUser: _vm.currentUser
        }
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "generate-url-popup",
            centered: "",
            title: "Generated URL",
            busy: "true",
            "hide-footer": ""
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "form-control d-flex align-items-center testing-code px-3 mb-2"
            },
            [
              _c("span", { staticClass: "code" }, [
                _vm._v(_vm._s(_vm.generatedUrl))
              ]),
              _c("input", {
                attrs: { type: "hidden", id: "generated-url" },
                domProps: { value: _vm.generatedUrl }
              })
            ]
          ),
          _vm.urlCopiedSuccessfully
            ? _c("div", { staticClass: "text-success mb-3 font-weight-bold" }, [
                _vm._v(" URL copied to clipboard successfully! ")
              ])
            : _vm._e(),
          _c("div", { staticClass: "text-right mb-2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary-dark mr-2",
                on: {
                  click: function($event) {
                    _vm.$bvModal.hide("generate-url-popup")
                    _vm.urlCopiedSuccessfully = false
                  }
                }
              },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.copyGeneratedUrl($event)
                  }
                }
              },
              [_vm._v(" Copy ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }